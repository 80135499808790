import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";


gsap.registerPlugin(ScrollTrigger);

const
isMobile = window.innerWidth<900,
header = document.querySelector("header"),
navigation = document.getElementById("navigation"),
body = document.querySelector("body"),
burger = document.getElementById("burger"),
menu_line_1 = document.getElementById("menuLine1"),
menu_line_2 = document.getElementById("menuLine2"),
menu_line_3 = document.getElementById("menuLine3"),
lines = document.querySelectorAll(".line");
let menu_state = false;
let lastScrollTop = window.scrollY,
offset = 200;

//______MENU ANIMATION______//

const closeMenu = ()=>{
    body.style.overflow = "initial"; 
    header.style.zIndex = 4;
    gsap.to(menu_line_1,{attr:{y1:40,y2:40,x1:23,x2:58}}); 
    gsap.to(menu_line_3,{attr:{y1:40,y2:40,x1:23,x2:58}}); 
    gsap.to(menu_line_2,{autoAlpha:1});
    gsap.to(menu_line_1,{attr:{y1:25.11,y2:25,x1:23,x2:58},delay:0.4});     
    gsap.to(menu_line_3,{attr:{y1:50.11,y2:50,x1:23,x2:58},delay:0.4});     
    gsap.to(navigation,{y:"-150%",autoAlpha:0,duration:0.5});
    menu_state = !menu_state;
};

const openMenu = ()=>{
    header.style.zIndex = 10;
    gsap.to(menu_line_1,{attr:{y1:40,y2:40,x1:23,x2:58}});    
    gsap.to(menu_line_3,{attr:{y1:40,y2:40,x1:23,x2:58}}); 
    gsap.to(menu_line_2,{autoAlpha:0});
    gsap.to(menu_line_1,{attr:{y1:53,y2:28,x1:26,x2:55},delay:0.4});    
    gsap.to(menu_line_3,{attr:{y1:28,y2:53,x1:26,x2:55},delay:0.4});
    gsap.to(navigation,{x:"-0%",y:"-0%",autoAlpha:1,duration:0.5});
    menu_state = !menu_state;
    body.style.overflow = "hidden";                     
};

burger.addEventListener("click",()=>{
    if(!menu_state){
        openMenu();
    }else{
        closeMenu();
    }
});

//Show-hide fixed menu
window.addEventListener('scroll',()=>{
    const scrollTopPosition = window.scrollY;
    if(scrollTopPosition == 0){ //Menu appear at top
        header.classList.remove("scrollView");
        gsap.to(header,{
            y:0,
            delay:0.5
          });
          return;
    }
    if (scrollTopPosition > lastScrollTop && scrollTopPosition > offset) { //Menu dissapear on scroll down
      gsap.to(header,{
        y:isMobile? -90 : -180,
      })
    } else if (scrollTopPosition>offset && scrollTopPosition < lastScrollTop) { //Menu appear on scroll up
      header.classList.add("scrollView");
      gsap.to(header,{
        y:0,
      })
    }
    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
  },
  false,
);


//______HOME ANIMATION______//

gsap.to(lines, {
    scrollTrigger: {
        trigger: "body", 
        toggleActions: "restart pause reverse reverse", 
        start: "1500px bottom",
        end: "bottom-=1000px center",
        scrub: 1,
    },
    strokeDashoffset: '0px',
    duration: 5,
    ease: "rough"
});